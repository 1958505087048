.input-box {
	background-color: #f7f7f7;
	border: 3px solid #666;
	outline: none;
	padding: 1rem;
	letter-spacing: 0.1rem;
	display: block;
	margin: 0.6rem 0;
	border-radius: 5px;
	width: 85%;
	font-size: 1.5rem;
	&:focus {
		border: 3px solid var(--green);
	}
}

.big {
	width: 95%;
}

.input-box-label {
	text-transform: capitalize;
	font-size: 1.5rem;
	margin-bottom: 1rem;
	display: inline-block;
}

@media (max-width: 1200px) {
	.input-box {
		width: 100%;
	}
}
