.profile-img {
	width: 40%;
	border-radius: 100%;
	cursor: pointer;
	border: 1.4px solid #fff;
	opacity: 0.9;
}

.profile-name-log {
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;

	&:hover {
		color: var(--green);
	}
}

.profile-img-active {
	opacity: 1;
	border: 1.4px solid rgb(119, 119, 119);
	box-shadow: 0px 0px 5px inset rgba(34, 34, 34, 0.5),
		0px 0px 5px rgba(34, 34, 34, 0.5);
}

.nameIcon {
	text-transform: capitalize;
	&:hover {
		color: var(--green);
		cursor: pointer;
	}
}

@media (max-width: 990px) {
	.profile-img {
		width: 8%;
	}
}
