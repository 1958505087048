.cart-icon {
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
	stroke: #282c3f;
	cursor: pointer;
	.icon {
		width: 100%;
		fill: #fff;
		stroke-width: 2px;
	}
	&:hover {
		stroke: var(--green);
		color: var(--green);
	}
	.qty {
		position: absolute;
		font-size: 1rem;
	}
	.cart-container {
		position: relative;
		width: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
	}
	.cart-content {
		padding-left: 1rem;
		margin-top: 3px;
	}
}

.empty-cart {
	width: 27%;
	background-color: #282c3f;
	color: white;
	position: absolute;
	top: 90%;
	right: 4%;
	border-radius: 10px;
	text-align: left;
	padding: 1rem;
	box-shadow: 2px 2px 8px rgba(40, 44, 63, 0.8);
	border-top: 4px solid var(--green);
	z-index: 1000;

	:hover {
		color: white !important;
	}
	.empty-card-title {
		padding-bottom: 0.5rem;
	}
	.empty-cart-sub {
		line-height: 1.7rem;
		display: block;
		padding-bottom: 1rem !important;
	}
	.empty-cart-btn {
		width: 100%;
		display: flex;
		justify-content: center;
	}
}

@media (max-width: 990px) {
	.cart-icon {
		justify-content: flex-start;
	}
	.icon {
		width: 28px !important;
	}
	.cart-container {
		width: 2% !important;
		justify-content: center !important;
	}
	.empty-cart {
		display: none;
	}
}

@media (max-width: 576px) {
	.cart-icon {
		justify-content: flex-start;
	}
	.icon {
		width: 50px !important;
	}
	.cart-container {
		width: 7% !important;
		justify-content: center !important;
	}
	.empty-cart {
		display: none;
	}
}
