.single-item {
	padding: 3rem;
	width: 100%;
	height: 100%;
	.single-item-headings {
		width: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		
		

		h1 {
			font-size: 5rem;
			cursor: pointer;
			text-transform: uppercase;
			color: var(--green);
		}
	}
	.single-item-items {
		width: 100%;
		display: grid;
		grid-template-columns: repeat(4, 1fr);
		gap: 15px;
		@media (max-width: 576px) {
			grid-template-columns: repeat(2, 1fr);
			
		}
	}
}
