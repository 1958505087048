.profile-toggle {
	width: 15%;
	position: absolute;
	right: 0.5%;
	background-color: #282c3f;
	padding: 2rem;
	display: flex;
	flex-direction: column;
	border-radius: 10px;
	box-shadow: 2px 2px 4px rgba(34, 34, 34, 0.5);
	border-top: 5px solid var(--green);
	gap: 15px;
	z-index: 1000;

	.profile-toggle-img-con {
		width: 100%;
		display: flex;
		justify-content: center;
		padding: 1rem;
	}

	.profile-toggle-img {
		border-radius: 100%;
		width: 30%;
		border: 1.4px solid rgb(223, 223, 223);
		box-shadow: 0px 0px 5px inset rgba(34, 34, 34, 0.5);
	}
	.progile-toggle-heading {
		text-align: center;
		margin-top: -10px;
		color: rgb(223, 219, 219);
	}
	.progile-toggle-name {
		text-transform: uppercase;
		font-size: 2rem;
	}
	.profile-toggle-email {
		font-size: 1.1rem;
	}
}
