.search-list {
	width: 100%;
	height: 12vh;
	display: flex;
	justify-content: center;
	margin-bottom: 0.7rem;
	.lists {
		width: 30%;
		height: 100%;
		margin-right: 10%;
		display: flex;
		justify-content: space-evenly;
		align-items: center;
		padding: 1rem 2rem;
		cursor: pointer;
		background-color: white;
		box-shadow: 0px 0px 4px rgba(34, 34, 34, 0.3);
		opacity: 0.9;
		@media (max-width: 576px) {
			width: 70%;
		}
		.search-list-img {
			width: 20%;
			height: 100%;
			background-repeat: no-repeat;
			background-position: center;
			background-size: cover;
			border-radius: 10px;
			@media (max-width: 576px) {
				width: 30%;
			}
		}
		.search-list-title {
			width: 30%;
			font-size: 1rem;
		}
		&:hover {
			color: var(--green);
			transform: scale(1.04);
			// box-shadow: 0px 0px 7px rgba(34, 34, 34, 0.7);
			opacity: 1;
		}
	}
	:first-child {
		margin-top: 0.5rem;
	}
}
