.dialog-heading {
	font-size: 2rem;
	color: #444;
	font-weight: bold;
	opacity: 0.8;

	&:hover {
		color: var(--green);
	}
}

.dialog-con-table {
	h1 {
		color: #222;
		font-size: 2.2rem;
		margin-left: -2px;
		padding: 0.5rem 0 1rem;
	}

	.dialog-con-table-child {
		i {
			width: 20px;
			color: var(--green);
			font-size: 1.5rem;
			opacity: 0.8;
		}
		span {
			font-size: 1.5rem;
		}
	}
}
