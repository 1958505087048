.sign-in-form {
	width: 100%;
	height: 88vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	padding: 3rem;
	gap: 2rem;
	.sign-in-form-con {
		display: flex;
		flex-direction: column;
		align-items: center;
		.sign-in-form-label {
			width: 85%;
			text-transform: capitalize;
			font-size: 1.5rem;
			margin-bottom: 1rem;
			display: inline-block;
		}
	}
	.sign-in-form-title {
		font-size: 4.7rem;
		color: var(--green);
	}
	.sign-in-form-subtitle {
		font-size: 2rem;
	}
	.form-in-sign-btn {
		margin-top: 3rem;
		width: 100%;
		display: flex;
		justify-content: space-evenly;
		align-items: center;
		gap: 10px;
	}

	@media (max-width: 1050px) {
		.sign-in-form-con {
			.sign-in-form-label {
				width: 100%;
			}
		}
		.sign-in-form-title {
			font-size: 3.5rem;
		}
		.sign-in-form-subtitle {
			font-size: 1.3rem;
		}
		.sign-in-form-headings {
			width: 100%;
			display: flex;
			flex-direction: column;
			align-items: center;
		}
		.form-in-sign-btn {
			width: 100%;
		}
	}

	@media (max-width: 400px) {
		.sign-in-form-title {
			font-size: 2.5rem;
		}
	}
}

.sign-in-out-text {
	width: 100%;
	display: flex;
	justify-content: center;
	// margin-right: 5rem;

	.sign-in-out-heading {
		font-size: 2.3rem;
	}
	.sign-in-out-sub-heading {
		cursor: pointer;
		color: var(--green);
		&:hover {
			text-decoration: underline;
		}
	}
}
