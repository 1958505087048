.message {
	display: flex;
	// align-items: center;
	min-height: 50px;
	// justify-content: center;
	// padding: 2rem;
	p {
		max-width: 500px;
		min-height: 40px;
		// display: flex;
		// align-items: center;
		// justify-content: center;
		// margin-bottom: 12px;
		// line-height: 24px;
		padding: 10px 20px;
		border-radius: 20px;
		// position: relative;
		color: #222;
		text-align: left;
		font-size: 2rem;
		// display: inline-block;
	}
	&.sent {
		flex-direction: row-reverse;
		// justify-content: flex-end;
		p {
			color: white;
			// background: #0b93f6;
			background-color: var(--green);
			align-self: flex-end;
			// text-align: right;
		}
	}

	&.received p {
		background: #e5e5ea;
		color: black;
	}

	img {
		width: 40px;
		height: 40px;
		border-radius: 50%;
		margin: 0.5rem 2rem 0;
	}
}
