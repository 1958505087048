.cart-box {
	position: absolute;
	width: 320px;
	height: 370px;
	background: white;
	z-index: 1000;
	right: 11%;
	top: 108%;
	border: 2px solid rgb(168, 168, 168);
	box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.1);
	padding: 1rem;
	.cart-buttons {
		width: 100%;
		height: 25%;
		display: flex;
		flex-direction: column;
		justify-content: flex-end;
		align-items: center;
		.cart-button {
			width: 100%;
			display: flex;
			justify-content: center;
			position: relative;
			z-index: 100;
		}
		.cart-price {
			width: 100%;
			display: flex;
			justify-content: flex-end;
			padding: 10px 15px 5px;
		}
	}
	.cart-list {
		width: 100%;
		height: 75%;
		overflow-x: hidden;
		.cart-list-child {
			height: 40%;
			display: flex;
			align-items: center;
			.cart-list-image {
				width: 30%;
				height: 80%;
				background-position: center;
				background-size: cover;
				border-radius: 10px;
			}
			.card-list-name {
				display: flex;
				flex-wrap: wrap;
				width: 45%;
				font-size: 1.5rem;
				padding-left: 10px;
			}
			.card-list-quantity {
				display: flex;
				width: 25%;
				justify-content: flex-end;
				margin-right: 10px;
				font-size: 1.4rem;
			}
		}
	}
	.empty-msg {
		display: flex;
		position: absolute;
		flex-direction: column;
		width: 100%;
		height: 100%;
		justify-content: center;
		align-items: flex-start;
		padding: 0 2rem 0 3rem;
		z-index: 80;

		h1 {
			font-size: 4rem;
			font-weight: bolder;
			color: #444;
		}
		p {
			color: #888;
		}
	}
}

// .hr {
// 	border: none;
// 	border-top: 3px solid var(--green);
// 	background-color: transparent;
// 	border-radius: 10px;
// }
