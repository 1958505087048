.start-page {
	width: 100%;
	height: 89vh;
	display: flex;
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;

	.transparent {
		width: 100%;
		height: 100%;
		position: absolute;
		background-image: linear-gradient(
			180deg,
			transparent,
			rgba(37, 37, 37, 0.65),
			black
		);
		z-index: 1;
	}

	.start-page-details {
		position: relative;
		width: 100%;
		height: 100%;
		padding: 8rem 3rem;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: flex-end;
		z-index: 10;
		.start-page-heading {
			font-size: 13rem;
			color: white;
			font-weight: bold;
			text-shadow: 0px 4px 15px rgb(0, 0, 0);
		}
		.start-page-subheading {
			font-size: 2.5rem;
			color: white;
			opacity: 0.8;
		}
		.start-page-btn-con {
			width: 100%;
			display: flex;
			justify-content: space-evenly;
			button {
				margin: 20px 0 0;
				padding: 16px 30px;
				border: none;
				outline: none;
				border-radius: 30px;
				font-size: 1.4rem;
				background: var(--green);
				color: white;
				opacity: 0.9;
			}
		}
	}

	.startpage-img-con {
		width: 50%;
		display: flex;
		align-items: center;
		.startpage-img {
			min-width: 100%;
			max-width: 100%;
			min-height: 400px;
			max-height: 400px;
			border: 5px solid transparent;
			border-radius: 15px;
			opacity: 0.96;
			transition: 0.7s;
		}
	}

	@media (max-width: 576px) {
		.start-page-details {
			.start-page-heading {
				font-size: 8rem;
			}
		}
	}
}
