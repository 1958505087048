.google-button {
	height: 45px;
	border-width: 0;
	background: white;
	color: #737373;
	border-radius: 5px;
	white-space: nowrap;
	box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.05);
	transition-property: background-color, box-shadow;
	transition-duration: 150ms;
	transition-timing-function: ease-in-out;
	padding: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	text-transform: uppercase;
	gap: 10px;
	padding: 0 10px;

	&:focus,
	&:hover {
		box-shadow: 1px 4px 5px 1px rgba(0, 0, 0, 0.1);
		color: #222;
	}

	&:active {
		background-color: #e5e5e5;
		box-shadow: none;
		transition-duration: 10ms;
	}
}

.google-button__icon {
	display: inline-block;
	vertical-align: middle;
	width: 18px;
	box-sizing: border-box;
}

.google-button__icon--plus {
	width: 27px;
}

.google-button__text {
	display: inline-block;
	vertical-align: middle;
	font-size: 1.7rem;
	font-family: "Roboto", arial, sans-serif;
}
