.table-btn {
	padding: 20px 28px;
	width: 100%;
	height: 100px;
	border: none;
	outline: none;
	font-size: 2rem;
	display: flex;
	border-radius: 15px;
	flex-direction: column;
	align-items: center;
	color: #fff;
	opacity: 0.9;
	span {
		font-size: 1.4rem;
	}

	&:hover {
		opacity: 1;
	}
}
