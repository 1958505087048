.triple-button-con {
	.child {
		padding: 1.5rem;
		border: none;
		outline: none;
		font-size: 1.5rem;
		font-family: IBM Plex Sans, sans-serif;
		font-weight: 500;
		// height: 100%;
		// display: flex;
		// align-items: center;
		// justify-content: center;
		color: rgb(231, 231, 231);
		text-transform: uppercase;
	}
}
