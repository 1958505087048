.overlay {
    height: 80vh;
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;

    $offset: 187;
    $duration: 1.4s;

    .spinner {
    animation: rotator $duration linear infinite;
    }

    @keyframes rotator {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(270deg); }
    }

        .path {
        stroke-dasharray: $offset;
        stroke-dashoffset: 0;
        transform-origin: center;
        animation:
            dash $duration ease-in-out infinite, 
            colors ($duration*4) ease-in-out infinite;
        }

        @keyframes colors {
            0% { stroke: #ee536d; }
            25% { stroke: rgb(231, 30, 64); }
            50% { stroke: #666; }
            75% { stroke: rgb(246, 23, 134); }
        100% { stroke: #ee536d; }
        }

            @keyframes dash {
            0% { stroke-dashoffset: $offset; }
            50% {
            stroke-dashoffset: $offset/4;
            transform:rotate(135deg);
            }
            100% {
            stroke-dashoffset: $offset;
            transform:rotate(450deg);
            }
            }
}

