.menu-foods {
	width: 100%;
	height: 60vh;

	.menu-foods-background {
		width: 100%;
		height: 90%;
		background-repeat: no-repeat;
		background-position: center;
		background-size: cover;
		border-radius: 15px;
		display: flex;
		justify-content: center;
		align-items: flex-end;
		padding-bottom: 2rem;
		cursor: pointer;
		opacity: 1;

		&:hover {
			opacity: 0.91;
			.cart-button {
				visibility: visible;
				opacity: 0.9;
			}
			.cart-triple-button {
				opacity: 0.85;
			}
		}

		.cart-button {
			visibility: hidden;
			transition: 100ms ease-in-out;
		}
		.cart-triple-button {
			opacity: 0;
			transition: 300ms ease-in-out;
		}
	}
	.menu-foods-details {
		width: 100%;
		height: 10%;
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 0 10px;
		text-transform: capitalize;
	}
}
