.button-box{
        display: inline-block;
        padding:.8rem 3rem;
        border:.2rem solid var(--green);
        color:var(--green);
        cursor: pointer;
        font-size: 1.7rem;
        border-radius: .5rem;
        position: relative;
        overflow: hidden;
        z-index: 0;
        background: white;
      }
      
.button-box::before{
        content: '';
        position: absolute;
        top:0; right: 0;
        width:0%;
        height:100%;
        background:var(--green);
        transition: .3s linear;
        z-index: -1;
      }
      
.button-box:hover::before{
    width:100%;
    left: 0;
}

.button-box:hover{
    color:#fff;
}
