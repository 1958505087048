.chat-home {
	width: 100%;
	height: 100vh;
	display: flex;
	background-color: var(--green);

	@media (max-width: 576px) {
		flex-direction: column;
	}

	.chat-room-content {
		width: 50%;
		height: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;

		@media (max-width: 576px) {
			width: 100%;
		}

		h1 {
			font-size: 8rem;
			font-weight: bold;
			margin-left: -5px;
			color: white;
		}
		h2 {
			font-size: 3rem;
			font-weight: bold;
		}
		p {
			font-size: 1.5rem;
			color: white;
		}
		button {
			padding: 15px 30px;
			border-radius: 4px;
			border: none;
			outline: none;
			background: #222;
			color: white;
			font-size: 1.3rem;
			box-shadow: 2px 2px 10px rgba(34, 34, 34, 0.6);
			opacity: 0.98;
			margin: 2rem 0;

			&:hover {
				box-shadow: 2px 2px 10px rgb(34, 34, 34);
				transform: scale(1.02);
			}
		}
	}
	.chat-room-image {
		width: 50%;
		height: 100%;
		display: flex;
		align-items: center;
		justify-content: center;

		@media (max-width: 576px) {
			width: 100%;
			img {
				width: 80%;
			}
		}
	}

	
}
