.table-page-container {
	.table-heading {
		width: 100%;
		display: flex;
		justify-content: center;
		padding: 2rem 0;
		h1 {
			font-size: 5rem;
			font-weight: bold;

			span {
				color: var(--green);
			}
		}
	}
	.table-page-items {
		width: 100%;
		display: flex;
		justify-content: space-evenly;
		align-items: center;

		h2 {
			width: 300px;
			font-size: 3rem;
			color: #222;
		}
		.table-page-select {
			width: 150px;
			overflow: hidden;
		}
	}
	.table-page-hr {
		width: 100%;
		display: flex;
		justify-content: center;
		hr {
			width: 70%;
		}
	}

	.tables-container {
		width: 100%;
		display: flex;
		justify-content: center;
	}

	.table-page-lunch {
		width: 100%;
		display: flex;
		flex-direction: column;
		padding: 2rem 0;
		justify-content: center;
		align-items: center;
		h1 {
			width: 100%;
			font-size: 3rem;
			display: flex;
			justify-content: center;
			padding: 2rem 0;
			color: var(--green);
			font-weight: bold;
		}
		.table-page-btn-child {
			width: 70%;
			display: grid;
			grid-template-columns: repeat(4, 1fr);
			gap: 20px;
		}
	}
}

.table-stripe-btn {
	width: 290px;
	height: 40px;
	background: var(--green);
	color: white;
	font-size: 1.6rem;
	text-transform: uppercase;
}
