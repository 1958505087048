.table-date-con {
	width: 110px;
	display: flex;
	flex-direction: column;
	align-items: center;
	.table-page-con {
		width: 90px;
		height: 90px;
		background-color: var(--green);
		color: #fff;
		margin: 10px;
		border-radius: 100px;
		display: flex;
		justify-content: center;
		align-items: center;
		cursor: pointer;
		opacity: 0.9;

		&:hover {
			opacity: 1;
		}
	}
	p {
		font-size: 1.5rem;
		color: #222;
	}
}
