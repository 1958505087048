.success-con {
	width: 100%;
	height: 100vh;
	display: flex;
	background-color: #fefefe;
	align-items: center;
	.success-img-con {
		width: 50%;
		display: flex;
		justify-content: flex-end;
		& img {
			width: 80%;
		}
	}
	.success-img-heading {
		width: 50%;
		height: 100%;
		display: flex;
		flex-direction: column;
		justify-content: center;
		& h1 {
			width: 100%;
			display: flex;
			justify-content: center;
			font-size: 5rem;
		}
		.success-logo {
			width: 100%;
			display: flex;
			justify-content: center;
			& a {
				font-size: 4rem;
				color: var(--green);

				animation: trans 2s ease-in-out infinite;
			}
		}
		.success-btn {
			height: 20vh;
			width: 100%;
			display: flex;
			justify-content: center;
			align-items: center;
		}
	}
}
