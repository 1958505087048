.table-home {
	width: 100%;
	height: 102vh;
	display: flex;
	background-color: var(--green);

	.table-home-img {
		width: 50%;
		height: 100%;
		display: flex;
		justify-content: flex-end;
		align-items: center;
	}

	.table-home-content {
		width: 50%;
		height: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		padding: 0 7rem;
		margin: 4rem 0;
		h1 {
			font-size: 9rem;
			font-weight: bold;
			margin-left: -5px;
			color: white;
		}
		h2 {
			font-size: 4rem;
			font-weight: bold;
			color: #222;
		}
		p {
			font-size: 2rem;
			color: white;
		}
		button {
			padding: 15px 30px;
			border-radius: 4px;
			border: none;
			outline: none;
			background: #222;
			color: white;
			font-size: 1.3rem;
			box-shadow: 2px 2px 10px rgba(34, 34, 34, 0.6);
			opacity: 0.98;
			margin: 2rem 0;

			&:hover {
				box-shadow: 2px 2px 10px rgb(34, 34, 34);
				transform: scale(1.02);
			}
		}
	}

	@media (max-width: 576px) {
		// 	.table-home-content {
		// 		padding: 0 5rem;
		// 		margin: 3rem 0;
		// 	}
		// 	h1 {
		// 		font-size: 7rem;
		// 	}
		// 	h2 {
		// 		font-size: 3rem;
		// 	}
		// 	p {
		// 		font-size: 1.5rem;
		// 	}

		flex-direction: column;

		.table-home-content {
			width: 100%;
			height: 100%;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			padding: 0 7rem;
			margin: 4rem 0;
	
			.table-res {
				display: flex;
				flex-direction: column;
				align-items: center;
			}
		}
		.table-home-img {
			width: 100%;
			height: 100%;
			display: flex;
			justify-content: center;
			align-items: center;
			img {
				width: 70%;
			}
		}
	}


}
