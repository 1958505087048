.menu-overview {
	width: 100%;
	display: flex;
	flex-direction: column;
	.menu-overview-heading {
		width: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		h1 {
			font-size: 5rem;
			cursor: pointer;
			text-transform: uppercase;
			color: var(--green);
		}
	}

	.menu-overview-items {
		width: 100%;
		display: grid;
		grid-template-columns: repeat(4, 1fr);
		gap: 15px;

		@media (max-width: 576px) {
			grid-template-columns: repeat(2, 1fr);
		}

	}
}
